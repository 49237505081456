import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PercentCrop, PixelCrop } from 'react-image-crop';
import { CompletedSpacing, CropImageModal, OriginalData } from './CropImageModal';
import { canvasPreview } from './canvasPreview';
import { Avatar, Typography } from '@mui/material';
import { getIcon } from '../CustomIcon';
import { useDropzone } from 'react-dropzone';
import { ScreenContext } from '../../../../../core/providers/ScreenProvider';
import { useSnackbar } from 'notistack';
import 'react-image-crop/src/ReactCrop.scss';

export const CropUploadField: FC<any> = (props) => {
  const {defaultImgSrc, onChange} = props;

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [imgSrc, setImgSrc] = useState(defaultImgSrc);
  const [newImgSrc, setNewImgSrc] = useState('');
  const [originalCrop, setOriginalCrop] = useState<PixelCrop | PercentCrop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | PercentCrop>();
  const [completedSpacing, setCompletedSpacing] = useState<CompletedSpacing>({});
  const [originalData, setOriginalData] = useState<OriginalData>();
  const [imageBitmap, setImageBitmap] = useState<ImageBitmap>();
  const [isOpenCropDialog, setIsOpenCropDialog] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    maxSize: 1024 * 1024, // 1 MB
    accept: ['image/png', 'image/jpeg'],
    onDrop: acceptedFiles => setFiles(acceptedFiles),
    onDropRejected: () => {
      enqueueSnackbar('Please upload the image in JPEG or PNG format, not bigger than 1Mb.', { variant: 'error' });
    }
  });
  const [files, setFiles] = useState([]);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  useEffect(() => {
    setImgSrc(defaultImgSrc)
  }, [defaultImgSrc])

  useEffect(() => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setNewImgSrc(reader.result.toString() || '');
        setIsOpenCropDialog(true);
      });
      reader.readAsDataURL(files[0]);
    }
  }, [files]);

  const handleCancelCrop = useCallback(() => {
    setFiles([]);
    setIsOpenCropDialog(false);
  }, []);

  const handleSaveCrop = (originalCrop: PercentCrop, completedCrop: PercentCrop, spacing: CompletedSpacing, originalData: OriginalData, imageBitmap: ImageBitmap) => {
    setOriginalData(originalData);
    setImageBitmap(imageBitmap);
    setOriginalCrop(originalCrop);
    setCompletedCrop(completedCrop);
    setCompletedSpacing(spacing);
    setIsOpenCropDialog(false);
  };

  let dropZoneDimension = 168;
  if (isTablet) {
    dropZoneDimension = 132;
  }
  if (isPhone) {
    dropZoneDimension = 132;
  }

  useEffect(() => {
    if (originalCrop?.width && originalCrop?.height && previewCanvasRef.current) {
      canvasPreview(
        originalData,
        imageBitmap,
        previewCanvasRef.current,
        originalCrop,
        1,
        dropZoneDimension
      );
    }
  }, [originalCrop, dropZoneDimension, imageBitmap]);

  const imageContent = () => {
    let content = null;

    if (Boolean(originalCrop) && imageBitmap) {
      content = <>
        <Avatar sx={{
          width: dropZoneDimension,
          height: dropZoneDimension,
          backgroundColor: 'white'
        }}>
          <canvas
            ref={previewCanvasRef}
            style={{
              width: dropZoneDimension,
              height: dropZoneDimension
            }}
          />
        </Avatar>
        <div className="dropzone__upload">
          <div>{getIcon('upload')}</div>
          <Typography>Drop your image</Typography>
          <Typography>here, or <span style={{ color: '#4A4DF0' }}>browse</span></Typography>
        </div>
      </>;
    } else if (imgSrc) {
      content = <>
        <Avatar sx={{
          width: dropZoneDimension,
          height: dropZoneDimension,
          backgroundColor: 'white'
        }}>
          <img src={imgSrc}/>
        </Avatar>
        <div className="dropzone__upload">
          <div>{getIcon('upload')}</div>
          <Typography>Drop your image</Typography>
          <Typography>here, or <span style={{ color: '#4A4DF0' }}>browse</span></Typography>
        </div>
      </>;
    } else {
      content = <>
        <div>{getIcon('upload')}</div>
        <Typography>Drop your image</Typography>
        <Typography>here, or <span style={{ color: '#4A4DF0' }}>browse</span></Typography>
      </>;
    }

    return content;
  };

  const isEmptyDropzone = !imgSrc && !imageBitmap;
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    width: `${dropZoneDimension}px`,
    height: `${dropZoneDimension}px`,

    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  const focusedStyle = {
    borderColor: '#4A4DF0'
  };
  const acceptStyle = {
    borderColor: '#2BB596'
  };
  const rejectStyle = {
    borderColor: '#E2507A'
  };
  const loadedStyle = {
    borderColor: '#2BB596',
    borderStyle: 'solid'
  };
  const emptyDropzoneStyle = {
    borderWidth: 2,
    borderRadius: '50%',
    borderColor: '#BBBDC0',
    borderStyle: 'dashed',
    backgroundColor: '#F5F5F5'
  };
  const dropZoneStyle = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    ...(isEmptyDropzone && files.length > 0 ? loadedStyle : {}),
    ...(isEmptyDropzone ? emptyDropzoneStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
    isEmptyDropzone
  ]) as any;

  useEffect(() => {
    onChange({
      files,
      completedCrop,
      completedSpacing
    })
  }, [files, completedCrop, completedSpacing])

  return <>
    <CropImageModal
      isOpenCropDialog={isOpenCropDialog}
      setIsOpenCropDialog={setIsOpenCropDialog}
      handleCancel={handleCancelCrop}
      handleSave={handleSaveCrop}
      imgSrc={newImgSrc}
    />
    <div className="dropzone" {...getRootProps({ style: dropZoneStyle })}>
      <input {...getInputProps()} />
      {
        imageContent()
      }
    </div>
  </>;
};
