import React, {FC, useContext, useEffect, useState} from 'react';
import { useQuery } from '@apollo/client';
import { GET_PARTICIPATION_REQUESTS } from '../../../../store/queries/requests';
import { Badge, BadgeProps, Grid, Link } from '@mui/material';
import { ProjectPRequest, ParticipationRequestStatusEnum } from '../../../../store/generated-models';
import DataTable, { COLUMN_TYPE, DataTableColumn } from '../../components/common/DataTable/DataTable';
import { getIcon } from '../../components/common/CustomIcon';
import { useNavigate } from 'react-router-dom';
import { Panel, RequestStatusChip } from '../../components/common';
import { numberFormat } from '../../../../core/helpers/utils';
import { styled } from '@mui/material/styles';
import { KycRestrictionPanel } from '../../admin/funds/KycRestrictionPanel';
import { AuthContext } from '../../../../core/providers/AuthProvider';

const moment = require('moment');

export const InvestorRequests: FC<any> = () => {
  const [requests, setRequests] = useState([]);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const isFullyVerified = authContext.isAdmin || authContext.isVerified
    && (authContext.user?.kybStatus === 'approved' || !authContext.isFundOwner);

  const { data, loading } = useQuery(GET_PARTICIPATION_REQUESTS, {
    fetchPolicy: 'network-only',
    skip: !isFullyVerified
  });

  useEffect(() => {
    if (loading || !isFullyVerified) {
    } else {
      setRequests(data.getProjectPRequests.list);
    }
  }, [data]);

  if (!isFullyVerified) {
    return <KycRestrictionPanel description='To enable participation in the projects and funds, we need to verify your identity'/>;
  }

  const KYBBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '&.MuiBadge-root': {
      display: 'flex'
    },
    '& .MuiBadge-badge': {
      borderRadius: '50%',
      right: '-16px',
      top: 0,
      width: '24px',
      height: '24px'
    }
  }));

  const columns: DataTableColumn[] = [
    {
      code: 'title',
      label: 'Project Name',
      style: {
        paddingRight: '32px'
      },
      render: (row: any, column: any) => {
        const { isLegalPerson } = row;

        return <>
          {
            isLegalPerson ?
              <KYBBadge color="error" badgeContent={getIcon('suitcase')}>
                <span>{row['project']['title']}</span>
              </KYBBadge>
              :
              <span>{row['project']['title']}</span>
          }
        </>;
      }
    },
    {
      code: 'created',
      label: 'Request Date',
      type: COLUMN_TYPE.DATE
    },
    {
      code: 'investmentApprovedAmount',
      label: 'Investment',
      render: (row: any, column: any) => {
        return `${numberFormat(row[column.code] || row['investmentAmount'])} ${row['investmentCurrency']}`;
      }
    },
    {
      code: 'status',
      label: 'Request Status',
      align: 'center',
      render: (row: any, column: any) => {
        const value = row[column.code];
        return <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RequestStatusChip status={value} sx={{ width: '100px' }}/>
        </div>;
      }
    },
    {
      code: 'saft',
      label: 'Contract',
      render: (row: any, column: any) => {
        const { contract } = row;
        const finalCopyUrl = contract ? contract.finalCopyUrl : null;

        return <>
          {contract?.created && <div style={{ display: 'flex' }}>
            {getIcon('file')}
            <Link //href={finalCopyUrl}
                  underline="none"
                  //onClick={(event) => event.stopPropagation()}
                  target="_blank"
            >{moment(contract.created).format('DD.MM.YYYY')}</Link>
          </div>
          }
        </>;
      }
    },
    {
      code: 'novations',
      label: 'Novation',
      render: (row: any, column: any) => {
        const { novations } = row;
        const finalCopyUrl = novations?.length ? novations[0].finalCopyUrl : null;

        return <>
          {novations[0]?.created && <div style={{ display: 'flex' }}>
            {getIcon('file')}
            <Link //href={finalCopyUrl}
                  underline="none"
                  //onClick={(event) => event.stopPropagation()}
                  target="_blank"
            >{moment(novations[0].created).format('DD.MM.YYYY')}</Link>
          </div>
          }
        </>;
      }
    },
    {
      code: 'signatureStatus',
      label: 'Signatures',
      render: (row: any, column: any) => {
        const { status, contract, novations } = row;
        let signedByBuyer = contract && contract.signedByBuyer;
        let signedBySeller = contract && contract.signedBySeller;

        if (novations?.length > 0 || status === ParticipationRequestStatusEnum.Novation) {
          signedByBuyer = novations[0]?.signedByBuyer;
          signedBySeller = novations[0]?.signedBySeller;
        }

        const buyerSign = signedByBuyer ? 'checkboxChecked' : 'checkboxUnchecked';
        const sellerSign = signedBySeller ? 'checkboxChecked' : 'checkboxUnchecked';

        return <div style={{ display: 'flex' }}>
          {novations?.length > 0 ?
            <>
              {getIcon(buyerSign)}
              {getIcon(sellerSign)}
            </>
            :
            <>
              {getIcon(buyerSign)}
              {getIcon(sellerSign)}
            </>
          }
        </div>;
      }
    }
  ];

  const rowOnClick = (row: ProjectPRequest) => {
    const id = row.projectPRequestId;

    if (id) {
      navigate(`/private/request-detailed/${id}`);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Panel>
            <DataTable rows={requests} columns={columns} isEditOnClick rowOnClick={rowOnClick} isWithPagination
                       isLoading={loading}/>
          </Panel>
        </Grid>
      </Grid>
    </>
  );
};
