import React, { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { concatClassNames } from '../../../../../core/helpers/utils';

export const SidebarMenuItem = (props: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { data, isPhone, handleDrawerClose, iconFill, onClick } = props;
  const openedCls = open ? 'menu__items--opened' : 'menu__items--closed';

  const location = useLocation();
  const classNames = concatClassNames({
    'menu__item--active': data.allowedPaths?.some((path: string) => {
      return location.pathname.search(path) > -1;
    }),
    'menu__item--group': data.menuItems?.length > 0
  });

  return (<li className={`menu__item ${classNames}`} onClick={(event) => {
      event.stopPropagation();
      if (onClick) {
        setOpen(!open);
        onClick(event);
      } else {
        if (data.menuItems?.length === 0) {
          navigate(`${data.link}`);
          if (isPhone) {
            handleDrawerClose();
          }
        } else {
          setOpen(!open);
        }
      }
    }
    }>
      <div className="menu__content">
        {
          data.icon &&
          <div className={`menu__icon ${iconFill ? 'menu__icon--fill' : ''}`}
               style={{ color: '#fff' }}>{data.icon}</div>
        }
        <div className="menu__label" style={{ color: '#fff' }}>{data.name}</div>
        {
          data.menuItems?.length > 0 && <div className="menu__collapser">
            {open ? <ExpandLess/> : <ExpandMore/>}
          </div>
        }
      </div>
      {
        data.menuItems?.length > 0 && <ul className={`menu__items ${openedCls}`}>
          {data.menuItems.map((item: any, index: number) => <SidebarMenuItem
            key={index} data={item} handleDrawerClose={handleDrawerClose} isPhone={isPhone}/>
          )}
        </ul>
      }
    </li>
  );
};
