import React, { FC, useContext, useState } from 'react';
import { Button, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FundsCards } from './FundCards';
import { FundsTable } from './FundsTable';
import { getIcon } from '../../components/common/CustomIcon';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../../../../core/providers/AuthProvider';
import { Panel } from 'bundles/private/components/common';
import { ArrowForward } from '@mui/icons-material';
import config from "../../../../config";
import {KycRestrictionPanel} from "./KycRestrictionPanel";

export const Funds: FC<any> = () => {
  const authContext = useContext(AuthContext);

  const [mode, setMode] = useState('table');

  const handleModeSwitch = (event: React.MouseEvent<HTMLElement>, newMode: string) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      marginLeft: '8px',
      padding: '8px',
      border: 0,
      backgroundColor: 'white',
      '&.Mui-selected': {
        backgroundColor: '#4A4DF0'
      }
    }
  }));

  const modeSwitcher = <StyledToggleButtonGroup
    value={mode} exclusive
    onChange={handleModeSwitch}
  >
    <ToggleButton value="table" color="primary">
      {getIcon('list', mode === 'table' ? 'stroke-white' : 'stroke-black')}
    </ToggleButton>
    <ToggleButton value="cards" color="primary">
      {getIcon('cards', mode === 'cards' ? 'stroke-white' : 'stroke-black')}
    </ToggleButton>
  </StyledToggleButtonGroup>;

  const isFullyVerified = authContext.isAdmin || authContext.isVerified
    && (authContext.user?.kybStatus === 'approved' || !authContext.isFundOwner);
  let keyRestriction = !authContext.isFundOwner
    ? 'To enable participation in the projects and funds, we need to verify your identity'
    : undefined; // Default description.

  return (
    <>
      { !isFullyVerified && <KycRestrictionPanel description={keyRestriction}/> }
      { isFullyVerified && mode === 'table' && <FundsTable toolbarItem={modeSwitcher}/> }
      { isFullyVerified && mode === 'cards' && <FundsCards toolbarItem={modeSwitcher}/> }
    </>
  );
};
