import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_FUND_PARTICIPATION_REQUEST_BY_ID } from '../../../../../store/queries/requests';
import {
  ParticipationRequestStatusEnum,
  RollingFundPRequest
} from '../../../../../store/generated-models';
import { TransactionList } from '../../../components/common/business/History/TransactionList';
import { Panel } from '../../../components/common/';
import { AuthContext } from '../../../../../core/providers/AuthProvider';
import { ScreenContext } from '../../../../../core/providers/ScreenProvider';
import { RequestProgress } from './details/RequestProgress/RequestProgress';
import { RequestSummary } from './details/RequestSummary/RequestSummary';
import { CurrentSAFT } from './details/CurrentSAFT/CurrentSAFT';
import { useDocumentSign } from './details/useDocumentSign';

export const FundParticipationRequestDetailed = () => {
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const { data, loading, refetch } = useQuery(GET_FUND_PARTICIPATION_REQUEST_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      participationRequestId: id
    }
  });
  const [request, setRequest] = useState<RollingFundPRequest>();
  const documentSignModule = useDocumentSign(request, refetch);

  useEffect(() => {
    if (!loading) {
      setRequest(data.getRollingFundPRequestById);
    }
  }, [data]);

  const isDataReady = request && request.rollingFundPRequestId;
  const isRequestProgressVisible = isDataReady;
  const isRequestSummaryVisible = isDataReady;
  const isCurrentSAFTVisible = isDataReady && request.contract && [ParticipationRequestStatusEnum.Approved].includes(request.status);
  const isHistoryVisible = isDataReady && request.userActionLog.length > 0;

  const TwoSidePanelSkeleton = () => <>
    <Panel>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
          <Skeleton variant="text"/>
        </Grid>
      </Grid>
    </Panel>
  </>;

  const PanelSkeleton = () => <>
    <Panel>
      <Skeleton variant="text"/>
      <Skeleton variant="text"/>
      <Skeleton variant="text"/>
      <Skeleton variant="text"/>
    </Panel>
  </>;

  return (
    <>
      {
        <Box sx={{ maxWidth: '1600px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={loading || request?.userActionLog?.length > 0 ? 9 : 12}>
              <Grid container spacing={3}>
                {
                  authContext.isUser && <>
                    {
                      loading ?
                        <Grid item xs={12}><PanelSkeleton/></Grid>
                        :
                        isRequestProgressVisible && <Grid item xs={12}>
                          <RequestProgress
                            request={request} refetch={refetch}
                            documentSignModule={documentSignModule}/>
                        </Grid>
                    }
                  </>
                }
                {
                  <>
                    {
                      loading ?
                        <Grid item xs={12}><TwoSidePanelSkeleton/></Grid>
                        :
                        isRequestSummaryVisible && <Grid item xs={12}>
                          <RequestSummary request={request} refetch={refetch}/>
                        </Grid>
                    }
                  </>
                }
                {
                  <>
                    {
                      loading ?
                        <Grid item xs={12}><TwoSidePanelSkeleton/></Grid>
                        :
                        isCurrentSAFTVisible && <Grid item xs={12}>
                          <CurrentSAFT
                            request={request}
                            documentSignModule={documentSignModule}/>
                        </Grid>
                    }
                  </>
                }
              </Grid>
            </Grid>
            {
              <Grid item xs={12} xl={3}>
                <Grid container>
                  <Grid item xs={12}>
                    {
                      loading ?
                        <PanelSkeleton/>
                        :
                        isHistoryVisible && <TransactionList request={request}/>
                    }
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Box>
      }
    </>
  );
};
