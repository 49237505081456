import {
  ProjectRoundInput,
  ProjectRoundMutationInput,
  SocialDataInput,
  SocialDataMutationInput, WalletInput, WalletMutationInput
} from '../../../store/generated-models';
import {crudStatus} from '../../../core/constants/common';

export const normalizeRounds = (rounds: Array<Record<string, any>>, status: crudStatus) => {
  return rounds.filter(item => item.status === status).map((item) => {
    const st = {
      title: item.title,
      price: parseFloat(item.price),
      tokenCount: parseInt(item.tokenCount)
    };

    switch (status) {
      case crudStatus.CREATED:
        const roundCreatedSt: ProjectRoundInput = st;
        return roundCreatedSt;
      case crudStatus.UPDATED:
        const roundUpdatedSt: ProjectRoundMutationInput = {projectRoundId: item.projectRoundId, ...st};
        return roundUpdatedSt;
      default:
        return item.projectRoundId;
    }
  });
};

export const normalizeSocials = (socials: Array<Record<string, any>>, status: crudStatus) => {
  return socials.filter(item => item.status === status).map((item) => {
    const st = {
      profileUrl: item.profileUrl,
      profileName: item.profileName,
      socialCode: item.social.socialCode
    };

    switch (status) {
      case crudStatus.CREATED:
        const socialCreatedSt: SocialDataInput = st;
        return socialCreatedSt;
      case crudStatus.UPDATED:
        const socialUpdatedSt: SocialDataMutationInput = {socialDataId: item.socialDataId, ...st};
        return socialUpdatedSt;
      default:
        return item.socialDataId;
    }
  });
};

export const normalizeWallets = (wallets: Array<Record<string, any>>, status: crudStatus) => {
  return wallets.filter(item => item.status === status).map((item) => {
    const st = {
      currency: item.currency,
      link: item.link,
    };

    switch (status) {
      case crudStatus.CREATED:
        const walletCreatedSt: WalletInput = st;
        return walletCreatedSt;
      case crudStatus.UPDATED:
        const walletUpdatedSt: WalletMutationInput = {walletId: item.walletId, ...st};
        return walletUpdatedSt;
      default:
        return item.walletId;
    }
  });
};
