import React, { FC, useContext } from 'react';
import { InvestorDetailed } from '../../../user/investorDetailed/InvestorDetailed';
import { AuthContext } from '../../../../../core/providers/AuthProvider';
import { FundOwnerDetailed } from './FundOwnerDetailed';

export const Profile: FC<any> = (props) => {
  const authContext = useContext(AuthContext);

  return (
    <>
      {
        authContext.isFundOwner && <FundOwnerDetailed {...props}/>
      }
      {
        !authContext.isFundOwner && <InvestorDetailed {...props}/>
      }
    </>
  );
};
