import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../../../store/queries/users';
import DataTable, { COLUMN_TYPE, DataTableColumn } from '../../components/common/DataTable/DataTable';
import { Chip, Grid } from '@mui/material';
import { FilterOperatorEnum, User, UserState } from '../../../../store/generated-models';
import { Panel } from '../../components/common';
import { Social } from '../../components/common/Social';
import { Filters } from '../../components/common/DataTable/Filters';
import { Search } from '../../components/common/DataTable/Search';
import { useFilters } from '../../hooks/useFilters';
import { PageToolbar } from '../../components/common/PageToolbar/PageToolbar';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';
import {renderKycStatusChip} from "../investors/Investors";

export const FundManagers: FC = () => {
  const navigate = useNavigate();
  const [fundManagers, setFundManagers] = useState([]);
  const { queryVariables, onFilterChange, onSearchChange } = useFilters();
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const defaultRoleFilter =  {
    field: 'role',
    fieldFilters: [{
      operator: FilterOperatorEnum.Eq,
      value: 'FUND_OWNER'
    }]
  };

  const { data, refetch, loading } = useQuery(GET_USERS, {
    variables: {
      filterEx: [...queryVariables.queryFilters, defaultRoleFilter],
      orderBy: queryVariables.sorters
    },
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    if (loading) {
    } else {
      setFundManagers(data?.getUsers?.list || []);
    }
  }, [data]);

  const rowOnClick = (row: User) => {
    const id = row.userId;

    if (id) {
      navigate(`/private/fund-owner-detailed/${id}`);
    }
  };

  const columns: DataTableColumn[] = [{
    code: 'lastName',
    label: 'Last Name',
    width: '300px',
    render: (row: any, column: any) => {
      return row['lastName'];
    }
  }, {
    code: 'firstName',
    label: 'First Name',
    width: '300px',
    render: (row: any, column: any) => {
      return row['firstName'];
    }
  }, {
    code: 'email',
    label: 'Email',
    width: '400px',
    render: (row: any, column: any) => {
      return row['email'];
    }
  }, {
    code: 'created',
    label: 'Creation Date',
    width: '200px',
    type: COLUMN_TYPE.DATE,
    sorter: {
      options: [
        {
          label: 'New first',
          value: true
        },
        {
          label: 'Old first',
          value: false
        }
      ]
    }
  },
    {
      code: 'socials',
      label: '',
      render: (row: any, column: any) => {
        const socials = row[column.code];

        return <div className="socials">
          {
            socials.map((socialData: Record<string, any>, idx: number) => <React.Fragment key={idx}>
                <Social key={idx} socialCode={socialData.social.socialCode} href={socialData.href}/>
              </React.Fragment>
            )
          }
        </div>;
      }
    }, {
      code: 'status',
      label: 'Status',
      width: '100px',
      align: 'center',
      render: (row: any, column: any) => {
        const value: UserState = row[column.code];
        let color = '';
        let backgroundColor = '';
        let label = '';

        switch (value) {
          case 'active':
            label = 'Active';
            backgroundColor = '#D6F5EE';
            color = '#2BB596';
            break;
          case 'inactive':
            label = 'Inactive';
            backgroundColor = '#FAE2E9';
            color = '#E2507A';
            break;
        }

        return (
          <Chip label={label}
                sx={{
                  color: color,
                  width: '100px',
                  backgroundColor: backgroundColor
                }}
          />
        );
      },
      sorter: {
        options: [
          {
            label: 'Active',
            value: false
          },
          {
            label: 'Inactive',
            value: true
          }
        ]
      }
    }, {
      code: 'kycStatus',
      label: 'KYC Status',
      width: '150px',
      align: 'center',
      render: (row: any, column: any) => {
        const value: string = row[column.code];
        return renderKycStatusChip(value);
      },
      filter: {
        type: 'checkbox',
        options: [
          {
            name: 'approved',
            label: 'Verified',
            value: 'approved'
          },
          {
            name: 'rejected',
            label: 'Rejected',
            value: 'rejected'
          },
          {
            name: 'waiting',
            label: 'Pending',
            value: 'waiting'
          },
          {
            name: 'not_verified',
            label: 'Not Verified',
            value: 'not_verified'
          }
        ]
      }
    }, {
      code: 'kybStatus',
      label: 'KYB Status',
      width: '150px',
      align: 'center',
      render: (row: any, column: any) => {
        const value: string = row[column.code];
        return renderKycStatusChip(value);
      },
      filter: {
        type: 'checkbox',
        options: [
          {
            name: 'approved',
            label: 'Verified',
            value: 'approved'
          },
          {
            name: 'not_verified',
            label: 'Not Verified',
            value: 'not_verified'
          }
        ]
      }
    }
  ];

  useEffect(() => {
    refetch();
  }, [queryVariables]);

  const filterBlockStyles: any = {
    display: 'flex',
    justifyContent: !isMobile ? 'end' : 'normal',
    flex: 1,
    marginLeft: isPhone ? 'inherit' : 'auto',
    position: isMobile ? 'fixed' : 'inherit',
    right: isPhone ? '16px' : '24px',
    left: isPhone ? '16px' : 'auto'
  };

  const getToolbarContainerHeight = () => {
    let height = '48px';

    if (isPhone) {
      height = '112px';
    }

    return height;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ minHeight: getToolbarContainerHeight() }}>
          <PageToolbar
            leftSide={
              <>
                {/*{*/}
                {/*  !isPhone && <RouterLink to="/private/fund-owner-detailed" style={{ textDecoration: 'none' }}>*/}
                {/*    <Button variant="contained">Create Investor</Button>*/}
                {/*  </RouterLink>*/}
                {/*}*/}
                <div style={filterBlockStyles}>
                  <Filters onChange={onFilterChange} columns={columns}/>
                  <Search placeholder="Search by First/Last Name or Telegram" onChange={onSearchChange}/>
                </div>
                {/*{*/}
                {/*  isPhone && <div style={{ marginTop: '54px' }}>*/}
                {/*    <RouterLink to="/private/investor-detailed" style={{ textDecoration: 'none', width: '100%' }}>*/}
                {/*      <Button variant="contained" style={{ width: 'calc(100vw - 32px)', height: '42px' }}>*/}
                {/*        Create Investor*/}
                {/*      </Button>*/}
                {/*    </RouterLink>*/}
                {/*  </div>*/}
                {/*}*/}
              </>
            }/>
        </Grid>
        <Grid item xs={12}>
          <Panel>
            <DataTable rows={fundManagers} columns={columns} isEditOnClick rowOnClick={rowOnClick} isWithPagination
                       isLoading={loading}/>
          </Panel>
        </Grid>
      </Grid>
    </>
  );
};
