import React from 'react';

import { PrivateSales } from './user/privateSales/PrivateSales';
import { ProjectDetailedView } from './admin/projects/ProjectDetailedView';
import { Navigate, Route } from 'react-router-dom';
import { Requests } from './admin/requests/Requests';
import { Investors } from './admin/investors/Investors';
import { InvestorRequests } from './user/requests/InvestorRequests';
import { ParticipationRequest } from './user/participationRequest/ParticipationRequest';
import { ParticipationRequestDetailed } from './user/participationRequestDetailed/ParticipationRequestDetailed';
import { ChangeRequest } from './user/participationRequest/ChangeRequest';
import { AuthContextProps } from '../../core/providers/AuthProvider';
import { InvestorDetailed } from './user/investorDetailed/InvestorDetailed';
import { InternalPage } from './components/layout/internalPage/InternalPage';
import { Security } from './user/settings/Security';
import { ContactSupport } from './user/contactSupport/ContactSupport';
import { FAQ } from './user/faq/FAQ';
import { Funds } from './admin/funds/Funds';
import { FundDetailedView } from './admin/funds/FundDetailedView';
import { FundParticipationRequest } from './admin/funds/ParticipationRequest/FundParticipationRequest';
import { FundRequests } from './admin/funds/FundRequests';
import { ProjectsSwitch } from './admin/projects/ProjectsSwitch';
import { FundParticipationRequestDetailed } from './admin/funds/ParticipationRequest/ParticipationRequestDetailed';
import { FundsCards } from './admin/funds/FundCards';
import { FundChangeRequest } from './admin/funds/ParticipationRequest/ChangeRequest';
import { Disclaimer } from './admin/funds/Disclaimer';
import { FundOwnerDetailed } from './components/common/Profile/FundOwnerDetailed';
import { Profile } from './components/common/Profile/Profile';
import { FundManagers } from './admin/fundManagers/FundManagers';

const Default: React.FC<any> = (props) => {
  return <Navigate to={props.path}/>;
};

export const privateRoutes = (authContext: AuthContextProps) => {
  let resultRoutes: any[] = [];

  const adminRoutes = [
    <Route path="projects" key="projects" element={<InternalPage title="Projects"><ProjectsSwitch/></InternalPage>}/>,
    <Route path="project-detailed" key="projectDetailed">
      <Route index element={<InternalPage title="Projects"><ProjectDetailedView/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Projects"><ProjectDetailedView/></InternalPage>}/>
    </Route>,
    <Route path="funds" key="funds" element={<InternalPage title="Rolling funds"><Funds/></InternalPage>}/>,
    <Route path="requests" key="requests" element={<InternalPage title="Requests"><Requests/></InternalPage>}/>,
    <Route path="disclaimer" key="disclaimer" element={<InternalPage title="Disclaimer"><Disclaimer/></InternalPage>}/>,
    <Route path="investors" key="investors" element={<InternalPage title="Investors"><Investors/></InternalPage>}/>,
    <Route path="fund-managers" key="fund_managers" element={<InternalPage title="Fund Managers"><FundManagers/></InternalPage>}/>,
    <Route index key="default" element={<Default path='/private/projects'/>}/>
  ];
  const userRoutes = [
    <Route path="projects" key="projects">
      <Route index element={<InternalPage title="Projects"><PrivateSales/></InternalPage>}/>
      <Route path="participation-request/:id"
             element={<InternalPage title="Participation request"><ParticipationRequest/></InternalPage>}/>
    </Route>,
    <Route path="funds" key="funds">
      <Route index element={<InternalPage title="Rolling funds"><FundsCards/></InternalPage>}/>
      <Route path="participation-request/:id"
             element={<InternalPage title="Participation request"><FundParticipationRequest/></InternalPage>}/>
    </Route>,
    <Route path="investor-requests" key="investor-requests"
           element={<InternalPage title="Requests"><InvestorRequests/></InternalPage>}/>,
    <Route path="change-request" key="change-request">
      <Route index element={<InternalPage title="Requests"><ChangeRequest/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Requests"><ChangeRequest/></InternalPage>}/>
    </Route>,
    <Route path="fund-change-request" key="fund-change-request">
      <Route index element={<InternalPage title="Requests"><FundChangeRequest/></InternalPage>}/>
      <Route path=":requestId" element={<InternalPage title="Requests"><FundChangeRequest/></InternalPage>}/>
    </Route>,
    <Route index key="default" element={<Default path='/private/projects'/>}/>
  ];

  const fundOwnerRoutes = [
    <Route path="funds" key="funds" element={<InternalPage title="Rolling funds"><Funds/></InternalPage>}/>,
    <Route path="fund-detailed" key="fundDetailed">
      <Route index element={<InternalPage title="Rolling Funds"><FundDetailedView/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Rolling Funds"><FundDetailedView/></InternalPage>}/>
    </Route>,
    <Route path="investors" key="investors" element={<InternalPage title="Investors"><Investors/></InternalPage>}/>,
    <Route index key="default" element={<Default path='/private/funds'/>}/>
  ];

  const mutualRoutes = [
    <Route path="fund-detailed" key="fundDetailed">
      <Route index element={<InternalPage title="Rolling Funds"><FundDetailedView/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Rolling Funds"><FundDetailedView/></InternalPage>}/>
    </Route>,
    <Route path="fund-requests" key="fund_requests"
           element={<InternalPage title="Requests"><FundRequests/></InternalPage>}/>,
    <Route path="fund-request-detailed" key="fund-request-detailed">
      <Route index element={<InternalPage title="Requests"><FundParticipationRequestDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Requests"><FundParticipationRequestDetailed/></InternalPage>}/>
    </Route>,
    <Route path="request-detailed" key="request-detailed">
      <Route index element={<InternalPage title="Requests"><ParticipationRequestDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Requests"><ParticipationRequestDetailed/></InternalPage>}/>
    </Route>,
    <Route path="investor-detailed" key="investor-detailed">
      <Route index element={<InternalPage title="Investors"><InvestorDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Investors"><InvestorDetailed/></InternalPage>}/>
    </Route>,
    <Route path="fund-owner-detailed" key="investor-detailed">
      <Route index element={<InternalPage title="Fund owners"><FundOwnerDetailed/></InternalPage>}/>
      <Route path=":id" element={<InternalPage title="Fund owners"><FundOwnerDetailed/></InternalPage>}/>
    </Route>,

    <Route path="profile" key="profile"
           element={<InternalPage title="Profile"><Profile isCurrentUser/></InternalPage>}/>,
    <Route path="security" key="security" element={<InternalPage title="Security"><Security/></InternalPage>}/>,
    <Route path="faq" key="faq" element={<InternalPage title="FAQ"><FAQ/></InternalPage>}/>,
    <Route path="contact-support" key="contact-support"
           element={<InternalPage title="Contact Support"><ContactSupport/></InternalPage>}/>
  ];

  if (authContext.isAdmin) {
    resultRoutes = [...resultRoutes, ...adminRoutes];
  }
  if (authContext.isUser) {
    resultRoutes = [...resultRoutes, ...userRoutes];
  }
  if (authContext.isFundOwner) {
    resultRoutes = [...resultRoutes, ...fundOwnerRoutes];
  }

  console.log(resultRoutes);
  return [...resultRoutes, ...mutualRoutes];
};
