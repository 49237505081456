import {gql} from '@apollo/client';

export const SETTINGS_RESULT = `
	companyKycFileDownloadUrl
	kycFileTypes {
		id
		index
		value
		required
		requiredBeneficiaryCount
	}
	maxFileSize
	maxFiles
	restrictMinAmounts
`;

export const LOGIN_RESULT = `
	authToken
	authTokenAction
	authTokenActionParam
	user {				
		userId
      email
      mode
      firstName
      lastName
      fullName
      accessFailedCount
      avatar
      birthday
      emailConfirmed
      hasEmailAuth
      is2faEnabled
      kycStatus
      kybStatus
      kycValid
      phone
      termsOfUse
      status
      created
      updated
      deleted
      roles {
        name
        code
        immutable
      }
      menuItems {
        code
        description
        name
      }
      wallets {
        walletId
        link
        currency
        createdAt
        deleted
      }
	}
`;

export const gqlLogin = gql`
  mutation Login(
    $email: String,
    $password: String,
    $oAuthProvider: OAuthProvider,
    $oAuthToken: String,
    $recaptcha: String!
  ) {
    login (
      email:$email,
      password:$password,
      oAuthProvider: $oAuthProvider,
      oAuthToken: $oAuthToken,
      recaptcha: $recaptcha
    ) {
      ${LOGIN_RESULT}
    }
  }
`;

export const gqlVerify2faCode = gql`
  mutation Verify2faCode(
    $code: String!,
    #$token: String!
    #$recaptcha: String!,
  ) {
    verify2faCode (
      code: $code,
      #token: $token
      #recaptcha: $recaptcha,
    ) {
      ${LOGIN_RESULT}
    }
  }
`;

export const gqlSignup = gql`
  mutation Signup(
    $email: String!,
    $password: String,
    $firstName: String, 
    $lastName: String,
    #$oAuthProvider: OAuthProvider,
    #$oAuthToken: String,
    $termsOfUse: Boolean!,
    #$name: String,
    # $givenReferralCode: String,
    $mode: UserMode!,
    $recaptcha: String!,
    $accountType: AccountType
  ) {
    signup (
      email: $email,
      password: $password,
      firstName: $firstName,
      lastName: $lastName,
      #oAuthProvider: $oAuthProvider,
      #oAuthToken: $oAuthToken,
      termsOfUse: $termsOfUse,
      #name : $name,
      # givenReferralCode: $givenReferralCode,
      recaptcha: $recaptcha
      mode: $mode
      accountType: $accountType
    ) {
      ${LOGIN_RESULT}
    }
  }
`;

