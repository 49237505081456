import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers($filterEx: [Filter], $orderBy: [OrderBy!]) {
    getUsers(filterEx: $filterEx, orderBy: $orderBy) {
      list {
        userId
        firstName
        lastName
        email
        socials {
          profileName
          profileUrl
          href
          social {
            socialCode
            title
          }
        }
        created
        mode
        kycStatus
        kybStatus
        status
        roles {
          code
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query UserById($userId: String) {
    userById(userId: $userId) {
      userId
      firstName
      lastName
      email
      status
      mode
      kycStatus
      kybStatus
      wallets {
        walletId
        link
        currency
        createdAt
        deleted
      }
    }
  }
`;

export const GET_ME = gql`
  query Me {
    me {
      userId
      email
      mode
      firstName
      lastName
      fullName
      accessFailedCount
      avatar
      birthday
      emailConfirmed
      hasEmailAuth
      is2faEnabled
      kycStatus
      kycValid
      kybStatus
      phone
      termsOfUse
      status
      created
      updated
      deleted
      roles {
        name
        code
        immutable
      }
      menuItems {
        code
        description
        name
      }
      wallets {
        walletId
        link
        currency
        createdAt
        deleted
      }
    }
  }
`;
