import React, { FC, useState, useEffect, useContext } from 'react';
import { Box, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useQuery } from '@apollo/client';
import { ProjectCard } from './ProjectCard';
import { GET_PROJECTS } from '../../../../store/queries/projects';
import { GET_PROJECTS_WITH_SUMMARY } from '../../../../store/queries/projects';
import Masonry from 'react-masonry-css';
import './PrivateSales.scss';
import { ProjectStatusEnum } from '../../../../store/generated-models';
import { AuthContext } from '../../../../core/providers/AuthProvider';
import { KycRestrictionPanel } from '../../admin/funds/KycRestrictionPanel';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div style={{ display: 'flex' }}
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

export const PrivateSales: FC<any> = (props: any) => {
  const { toolbarItem } = props;
  const authContext = useContext(AuthContext);
  const { data, refetch, loading } = useQuery(authContext.isAdmin ? GET_PROJECTS_WITH_SUMMARY : GET_PROJECTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const [projects, setProjects] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (loading) {
    } else {
      if (authContext.isAdmin) {
        const { getProjectsWithSummary } = data;
        const { list } = getProjectsWithSummary;
        setProjects(list);
      } else {
        const { getProjects } = data;
        const { list } = getProjects;
        setProjects(list);
      }
    }
  }, [data]);

  const handleActiveTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    refetch();
  };

  const activeProjects = !loading ? projects.filter(item => item.status === ProjectStatusEnum.Active)
    : new Array(6).fill(null).map((_, i) => i + 1);
  const pastProjects = !loading ? projects.filter(item => item.status === ProjectStatusEnum.Inactive)
    : new Array(6).fill(null).map((_, i) => i + 1);

  const activeProjectsNum = activeProjects.length;
  const [breakpointColumnsObj, setBreakpointColumnsObj] = useState({});
  const [pastProjectsBreakoints, setPastProjectsBreakoints] = useState({});

  useEffect(() => {
    setBreakpointColumnsObj({
      default: activeProjectsNum >= 5 ? 5 : activeProjectsNum,
      2300: activeProjectsNum >= 4 ? 4 : activeProjectsNum,
      1900: activeProjectsNum >= 3 ? 3 : activeProjectsNum,
      1000: 2,
      644: 1
    });
  }, [activeProjectsNum]);

  const pastProjectsNum = pastProjects.length;

  useEffect(() => {
    setPastProjectsBreakoints({
      default: pastProjectsNum >= 5 ? 5 : pastProjectsNum,
      2300: pastProjectsNum >= 4 ? 4 : pastProjectsNum,
      1900: pastProjectsNum >= 3 ? 3 : pastProjectsNum,
      1000: 2,
      644: 1
    });
  }, [pastProjectsNum]);

  const [isTablet, setIsTabled] = useState(false);
  const tablet = useMediaQuery('(max-width:1420px)');
  useEffect(() => {
    setIsTabled(tablet);
  }, [tablet]);

  const isFullyVerified = authContext.isAdmin || authContext.isVerified
    && (authContext.user?.kybStatus === 'approved' || !authContext.isFundOwner);

  return (
    <>
      {!isFullyVerified && <KycRestrictionPanel description='To enable participation in the projects and funds, we need to verify your identity.'/>}
      {isFullyVerified && <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Tabs
              value={activeTab}
              onChange={handleActiveTabChange}
              aria-label="secondary tabs example"
            >
              <Tab value={0} label="Active sales" sx={{ textTransform: 'none' }}/>
              <Tab value={1} label="Past sales" sx={{ textTransform: 'none' }}/>
            </Tabs>
            <div style={{ marginLeft: 'auto' }}>
              {toolbarItem}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={activeTab} index={0}>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {
                activeProjects.map((project, index) => {
                  return (
                    <ProjectCard key={project.projectId || index} isTablet={isTablet} {...project} isLoading={loading}/>
                  );
                })
              }
            </Masonry>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Masonry
              breakpointCols={pastProjectsBreakoints}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {
                pastProjects.map((project, index) => {
                  return (
                    <ProjectCard key={project.projectId || index} isTablet={isTablet} {...project} isLoading={loading}/>
                  );
                })
              }
            </Masonry>
          </TabPanel>
        </Grid>
      </Grid> }
    </>
  );
};
