import React, {FC, useCallback, useContext} from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Panel } from '../../components/common';
import {ScreenContext} from "../../../../core/providers/ScreenProvider";

import FAQ1 from '../../../../assets/faq/1.png';
import FAQ2 from '../../../../assets/faq/2.png';
import FAQ3 from '../../../../assets/faq/3.png';
import FAQ4 from '../../../../assets/faq/4.png';
import FAQ5 from '../../../../assets/faq/5.png';

export const FAQ: FC = () => {
  const screenContext = useContext(ScreenContext);
  const {isPhone} = screenContext;

  const cols = [{title: 'Private Sales', list: [
    {
      q: "1.	What is the role of Defied Bank on the Token Sales?",
      a: "Defied Trust is the legal structure that holds the responsibility for the Token sale making sure that all the regulations are met during the sale."
    }, {
      q: "2.	Why is Defied Bank based in Costa Rica?",
      a: <>
        <p>Defied Bank forms part of a conglomerate of structures located around the world being able to always find the
          best jurisdiction for our projects.</p>
        <p>Currently, we hold token sales in Costa Rica since it is crypto-friendly jurisdiction with low taxation.</p>
      </>
    }, {
      q: "3.	What is a SAFT?",
      a: "SAFT stands for SIMPLE AGREEMENT FOR THE FUTURE TOKENS."
    }, {
      q: "4.	Why the SAFT is in the name of Defied Bank and not the name of the project?",
      a: "The token sale is held under Defied Bank's legal structure and the development, marketing, and daily " +
        "operations of the project are being realized under a different corporation."
    }, {
      q: "4.	What is the contribution wallet address?",
      a: "The contribution wallet address is where the funds are sent to participate in the project's private sale. " +
        "Please, always double-check the chain that will be specified on the document. If you send your funds via the " +
        "wrong chain, your funds will be lost!"
    }, {
      q: "5.	What is the buyer's wallet address",
      a: "The Buyer's wallet address is the wallet where the buyer wishes to receive the tokens. Don't use your " +
        "exchange address. Preferably indicate a wallet that is multichain compatible. "
    }, {
      q: "6.	When is my SAFT going to be countersigned?",
      a: "Once the funds have been sent to the contribution wallet address, and you have signed the SAFT on the " +
        "platform we will be countersigning the SAFT."
    }, {
      q: "7.	What is the TX id?",
      a: <>
        <p>A TX ID or Transaction ID is a string of letters and numbers that identifies a specific transaction on the
          blockchain.</p>
        <p>You can find it on a BlockChain Explorer such as Ehterscan for ERC-20 transactions or Bscan for BEP-20
          transactions.</p>
      </>
    }, {
      q: "8.	When am I going to receive my tokens?",
      a: <>
        <p>The project will announce the Token Generation Event (TGE) on their website and socials. We recommend joining
          the Discord channels, TG groups, and Twitter for the latest updates.</p>
        <p>After TGE, you will receive your tokens accordingly with the cliffs and vesting schedules.</p>
      </>
    }, {
      q: "9.	Where will I receive my tokens?",
      a: "You will receive your tokens to the wallet you indicated in your SAFT-->  the Buyer's wallet address box."
    }
  ]}, {title: 'Rolling Funds', list: [
    {
      q: "1.	How are rolling funds different from venture fund investing?",
      a: <>
        <p>An LP (Liquidity provider) follows a flexible, monthly, quarterly, or yearly investment schedule rather than
          a one-time commitment to a fund.</p>
        <p>LPs can subscribe for future rolling funds in advance and easily commit more or less capital as their
          investment goals change.</p>
      </>
    }, {
      q: "2.	When will LPs learn about the companies the fund has invested in?",
      a: "There is no guarantee that LPs in the Program will receive information about the funds and underlying " +
        "portfolio companies. Fund updates, if any, will be provided solely at the fund’s Manager’s discretion."
    }, {
      q: "3.	How do rolling funds work?",
      a: <>
        <p>VCs can establish different terms. Some VCs might require a minimum investment and minimum period to
          subscribe funds or roll funds.</p>
        <ul style={{marginLeft: "32px"}}>
          <li>You will have to be approved by the VC to access their subscription program.</li>
          <li>Once approved, you will have to sign the subscription agreement and send the funds to the contribution wallet specified on the subscription agreement.</li>
          <li>Afterward, the fund manager will countersign the subscription agreement.</li>
          <li>LPs must fund their subscriptions by the funding deadline indicated on the Platform.</li>
          <li>At the end of the period specified on the subscription agreement, you will receive the interests depending on the fund’s performance minus the management fee (2%).</li>
        </ul>
      </>
    }, {
      q: "4.	What is the subscription agreement?",
      a: <>
        <p>The subscription agreement is the legal document you will have to sign to roll funds in our platform. On the
          subscription agreement, you will find the details of the Fund Manager (in case you might need to ask some
          additional questions), the contribution wallet address and its chain and the details of your subscription on
          the platform.</p>
        <p>When signing on the platform the subscription agreement, you will have to paste the “Etherscan address for
          the transaction showing the payment” and your wallet address where you will wish to receive the interests of
          the performance of the funds.</p>
        <p>If you have any questions, you can contact our team at: onboarding@defiedtrust.com</p>
      </>
    }, {
      q: "5.	Will I participate in all investments made by the fund?",
      a: <>
        <p>You will only participate in investments made by the fund during the period you have subscribed to.</p>
        <p>If you cancel or fail to fund your subscription, you will no longer participate in future subscription
          programs.</p>
      </>
    }, {
      q: "6.	Am I guaranteed access to rolling more funds in the future?",
      a: "No. VCs may cease offering new subscriptions at any time."
    }, {
      q: "7.	Can I sell my investment in the fund?",
      a: "You generally cannot sell or transfer your interest outside of serious events like death or divorce."
    }, {
      q: "8.	When will funds begin deploying capital?",
      a: "The Fund may warehouse investments or otherwise deploy all or a portion of its capital prior to accepting " +
        "and closing investors’ subscriptions."
    }, {
      q: "9.	What happens if a fund does not invest its total capital during the period, you have rolled funds?",
      a: <>
        <p>A fund will deploy capital into investments until the end of its investment period.</p>
        <p>If any fund in the Program does not invest all of its investable capital during its investment period, your
          proportionate share of capital may be reinvested into the next period. In this case, you will be informed by
          the fund manager and will be able to choose to be refunded or enter the next period for rolling funds.</p>
      </>
    }, {
      q: "10.	How does changing or canceling a subscription work?",
      a: <>
        <p>Subscriptions are not auto-renewed, so if you wish to increase or decrease your subscription, you will have
          to make a new application.</p>
        <p>You must fulfill your subscription period commitments before increasing or decreasing a new subscription.</p>
        <p>As long as the subscription agreement is not countersigned by the Fund Manager, you will be able to change
          terms or withdraw your application on the platform.</p>
      </>
    }, {
      q: "11.	How do management and admin fees work?",
      a: <>
        <p>Management fees are usually 2% of the fund's total amount.</p>
        <p>Some VCs may reduce or waive fees for certain investors and not others. In addition to the management fee,
          some funds may charge additional fees.</p>
      </>
    }, {
      q: "12. Can I opt-out of specific deals?",
      a: <>
        <p>No. LPs cannot choose whether to invest in specific deals.</p>
        <p>I you wish to invest in specific deals, you may have a look at the different token sales on our platform.</p>
      </>
    }, {
      q: "13.	How are subscriptions funded?",
      a: <>
        <p>You can roll funds to the contribution wallet address specified on the subscription document. The chain and
          currencies accepted will be indicated.</p>
        <p>Generally, Funds accept stablecoins such as USDC, USDT, DAI, and BUSD.</p>
        <p>If you have any questions related on how to send crypto, please, check our <strong>Beginner’s guide</strong>.</p>
      </>
    }
  ]}];

  return (
    <Box sx={{maxWidth: '1480px'}}>
      <Panel>
        <Grid container spacing={3}>
          { cols.map((faqList: any) =>
            <>
              <Grid item xs={8} sm={isPhone ? 12 : 6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{marginBottom: '32px'}}>
                    <Typography variant="h6">{faqList.title}</Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {faqList.list.map((faq: any) =>
                    <>
                      <Grid item xs={12}>
                        <Typography component="span" sx={{fontWeight: 600, fontSize: '16px'}}>
                          {faq.q}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{marginBottom: '24px'}}>
                        <Typography sx={{fontSize: '16px', color: '#7A858F', textAlign: 'left'}}>
                          {faq.a}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Panel><br/>

      <Panel>
        <Grid container spacing={3}>
          <Grid item xs={8} sm={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{marginBottom: '32px'}}>
                <Typography variant="h6">BEGINNERS GUIDE: HOW TO PURCHASE TOKENS FROM OUR PROJECTS:</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="span" sx={{fontWeight: 600, fontSize: '16px'}}>
                  SENDING FUNDS FROM METAMASK?
                </Typography>
              </Grid>
              <Grid item xs={12} style={{marginBottom: '24px'}}>
                <Typography sx={{fontSize: '16px', color: '#7A858F', textAlign: 'left'}}>
                  <p>Install metamask in your browser by clicking on the following link: <a href="https://metamask.io" target="_blanc">https://metamask.io</a></p>
                  <p>1.	If is the first time you are using Metamask, you need to create a new Wallet.<br/><br/><img src={FAQ1} width='400px'/></p>
                  <p>2.	You will be requested to create a new password.<br/><br/><img src={FAQ2} width='220px'/></p>
                  <p>3.	Afterwards, you will need to secure your wallet with the Secret Recovery Phrase. The Secret
                    Recovery Phrase is super important that you keep it in a safe place and you shouldn't disclose it to
                    anyone. Remember, anyone that might have access to your Secret Recovery Phrase could steal your funds.
                    <br/><br/><img src={FAQ3} width='400px'/></p>
                  <p>4.	You will be requested to confirm the Secret Recovery Phrase. Keep in mind, that you must keep
                    the Secret Recovery Phrase in the same order as it is shown in the previous step.</p>
                  <p>5.	You have your Metamask account ready!<br/><br/><img src={FAQ4} width='400px'/></p>
                  <p>6.	The account that you have is on the Ethereum mainnet.<br/><br/><img src={FAQ5} width='570px'/></p>
                  <p>7.	You can add different networks such as the Binance Smart Chain, Avalanche, Polygon… and for each
                    chain you will have a different wallet address.</p>
                  <p>8.	To check that the address has been correctly created, you can copy the wallet address and find
                    it on Etherscan.</p>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="span" sx={{fontWeight: 600, fontSize: '16px'}}>
                  SENDING FUNDS FROM A CRYPTO EXCHANGE?
                </Typography>
              </Grid>
              <Grid item xs={12} style={{marginBottom: '24px'}}>
                <Typography sx={{fontSize: '16px', color: '#7A858F', textAlign: 'left'}}>
                  <ol>
                    <li>Create an account in crypto exchange such as Binance, Kraken, Kucoin...</li>
                    <li>Copy the wallet for the currency that is requested</li>
                    <li>Paste the contribution wallet in your SAFT</li>
                    <li>Make sure you have enough ETH on your wallet to cover the GAS FEES</li>
                    <li>Check on Ehterscan the transaction</li>
                    <li>Copy the Tx ID from Etherscan</li>
                  </ol>
                </Typography>
                <p><br/>
                  <strong>Remember that when signing the SAFT, you will need to indicate a wallet where you wish to
                    receive the tokens. Never put your exchange wallet on the buyer's wallet address on the SAFT. In
                    most cases, we highly recommend providing a multiple chain wallet such as metamask, since some
                    projects will be generating their tokens in different chains.</strong>
                </p>
                <Typography sx={{fontSize: '16px', color: '#7A858F', textAlign: 'left'}}>
                  NOTE: We highly recommend using a desktop device during the process since some applications don't respond well on mobile devices.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Panel>
    </Box>
  );
};
