import React, { useContext, useEffect, useMemo, useState } from 'react';
import { privateRoutes } from './routes';
import { AuthContext } from '../../core/providers/AuthProvider';
import { AppBar, Box } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Sidebar } from './components/layout/sidebar/Sidebar';
import { Toolbar } from './components/layout/toolbar/Toolbar';
import { styled as styledMui } from '@mui/material/styles';
import { Routes } from 'react-router-dom';
import { ScreenContext } from '../../core/providers/ScreenProvider';

const Layout = (props: any) => {
  const [open, setOpen] = useState(false);
  const { isMobile } = useContext(ScreenContext);
  const { drawerWidth } = props;

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styledMui(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
  })<AppBarProps>(({ theme, open }) => ({
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`
    })
  }));

  return (
    <>
      <AppBar open={open} position="fixed"
              sx={{
                backgroundColor: isMobile ? '#1E232E' : 'inherit',
                boxShadow: 'none',
                color: isMobile ? '#fff' : '#000000'
              }}
      >
        <Toolbar handleDrawerOpen={handleDrawerOpen}
                 open={open}
        />
      </AppBar>
      <Sidebar handleDrawerClose={handleDrawerClose}
               handleDrawerOpen={handleDrawerOpen}
               open={open}
               drawerWidth={drawerWidth}
      />
    </>
  );
};

const Content = React.memo(() => {
  const authContext = useContext(AuthContext);

  return (
    <Routes>
      {privateRoutes(authContext)}
    </Routes>
  );
});

export const Private: React.FC<any> = () => {
  const authContext = useContext(AuthContext);
  const screenContext = useContext(ScreenContext);

  const { isPhone, isTablet } = screenContext;

  let drawerWidth = '240px';
  if (isTablet) {
    drawerWidth = '200px';
  }
  if (isPhone) {
    drawerWidth = '100%';
  }

  const Main = styledMui('div')<{
    open?: boolean;
  }>(({ theme, open }) => ({
    height: 'calc(100% - 64px)',
    overflow: 'auto',
    flexGrow: 1,
    marginTop: '64px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-start'
  }));


  return useMemo(() => {
    return (
      <Box sx={{ display: 'flex', backgroundColor: '#F5F5F5', height: '100vh' }}>
        <Layout drawerWidth={drawerWidth}/>
        <Main>
          <Content/>
        </Main>
      </Box>
    );
  }, [authContext.isInitialized, authContext.user, authContext.menuItems, drawerWidth, screenContext.isMobile]);
};
