import React, { FC, MouseEvent, useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button, Typography
} from '@mui/material';
import { AgreeTerms, SubscriptionTerms, Success } from './steps';
import { ScreenContext } from '../../../../../core/providers/ScreenProvider';
import { Modal, Panel } from '../../../components/common';
import './Disclaimer.scss';
import { AuthContext } from '../../../../../core/providers/AuthProvider';
import { useQuery } from '@apollo/client';
import { GET_DISCLAIMER } from '../../../../../store/queries/funds';

type OwnStepProps = {
  setIsStepValid?: (value: boolean) => void,
  ref?: any
}
export type StepProps = FC<OwnStepProps>

const StepContent: Record<number, StepProps> = {
  0: AgreeTerms,
  1: SubscriptionTerms,
  2: Success
};

export const FundParticipationRequest: FC = () => {
  const steps = ['Agree Terms', 'Subscription Terms', 'Success'];
  const [activeStep, setActiveStep] = useState(0);
  const [isStepValid, setIsStepValid] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const [createdItemId, setCreatedItemId] = useState(false);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const navigate = useNavigate();

  const ref = useRef();
  const authContext = useContext(AuthContext);

  function getStep(index = 0, props: Record<string, unknown> = {}) {
    const Step = StepContent[index];
    return <Step {...props} ref={ref}/>;
  }

  const handleBack = (_e: MouseEvent<HTMLButtonElement>) => {
    if (activeStep === 0) {
      navigate('/private/funds');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setIsStepValid(false);
    }
  };

  const handleNext = (_e: MouseEvent<HTMLButtonElement>) => {
    if (activeStep === 1) {
      setIsSendButtonDisabled(true);
      // @ts-ignore
      ref.current.handleSendParticipationRequest()
        .then((result: any) => {
          setCreatedItemId(result.data.addRollingFundPRequest.rollingFundPRequestId);
          setIsSendButtonDisabled(false);
        })
        .catch(() => {
          setIsSendButtonDisabled(false);
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setIsStepValid(false);
    }
  };

  useEffect(() => {
    if (createdItemId && activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [createdItemId]);

  const stepStyles = (index: number) => ({
    display: isPhone && activeStep !== index ? 'none' : 'block',
    ...(isPhone && activeStep === 0 && { marginLeft: '25%' }),
    ...(isPhone && activeStep === steps.length - 1 && { marginRight: '25%' })
  });

  const stepProps: { completed?: boolean } = {};

  const [isOpenDisclaimer, setIsOpenDisclaimer] = useState(false);

  useEffect(() => {
    setIsOpenDisclaimer(true);
  }, []);

  const { data: disclaimerData } = useQuery(GET_DISCLAIMER, {
    fetchPolicy: 'network-only'
  });
  const [disclaimer, setDisclaimer] = useState('');

  useEffect(() => {
    if (disclaimerData) {
      setDisclaimer(disclaimerData.getRollingFundsDisclaimer);
    }
  }, [disclaimerData]);

  return (
    authContext.isUser && <Panel>
      <Modal
        open={isOpenDisclaimer}
        title="Disclaimer"
        isLarge
        renderActions={() => <>
          <Button onClick={() => setIsOpenDisclaimer(false)}>I AGREE</Button>
        </>}
      >
        <div className="disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }}/>
      </Modal>

      <Stepper activeStep={activeStep} sx={{ margin: '0 5%' }}>
        {
          steps.map((label, index) => {
            return (
              <Step key={label} {...stepProps} style={stepStyles(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })
        }
      </Stepper>

      <Box sx={{ marginTop: '48px' }}>
        {getStep(activeStep, { setIsStepValid, isLegalPerson, setIsLegalPerson, createdItemId })}
      </Box>
      {activeStep !== steps.length - 1 && (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, paddingTop: '48px' }}>
          <Button
            variant="outlined"
            onClick={handleBack}
            sx={{ mr: 1, width: { xs: '140px', md: 'auto' } }}
          >
            {activeStep ? 'Back' : 'Cancel'}
          </Button>
          <Box sx={{ flex: '1 1 auto' }}/>
          <Button
            variant="contained"
            disabled={!isStepValid || isSendButtonDisabled}
            onClick={handleNext}
            sx={{ width: { xs: '140px', md: 'auto' } }}
          >
            {activeStep === 1 ? 'Send request' : 'Next'}
          </Button>
        </Box>
      )}
    </Panel>
  );
};

